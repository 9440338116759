import { combineReducers } from "redux";
import latestReducer from "./LatestNews";
import { quizReducer } from "./QuizDataReducers";
import { quizQuesReducer } from "./QuizQuesReducers";
import { filterReducer } from "./FilterReducers";
import { mcqQuestionsReducer } from "./McqQuestionsReducers";
import postsSlice from "./Posts/postsSlice";
import trendingSlice from "./TrendingNewsReducers/trendingSlice";
import userDetailsSlice from "./UserDetails/userDetailsSlice";


const rootReducer = combineReducers({
    trending: trendingSlice,
    latest: latestReducer,
    quizlist: quizReducer,
    quizQuesList: quizQuesReducer,
    userDatailsData: userDetailsSlice,
    filterCountList: filterReducer,
    mcqquestions: mcqQuestionsReducer,
    postsData: postsSlice
});

export default rootReducer;
