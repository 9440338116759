import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";
import { auth_service } from "../../../authService";

const loginSuccess = auth_service.isAuthenticated();

const fetchGlobalCommunityPost = createAsyncThunk('posts/fetchPosts', async (data, { rejectWithValue }) => {
    try {
        data.loginSuccess = loginSuccess;
        let response = await axios.get(Apis.GetGlobalCommunityPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchMoreGlobalCommunityPost = createAsyncThunk('posts/fetchMorePosts', async (data, { rejectWithValue }) => {
    try {
        data.loginSuccess = loginSuccess;
        let response = await axios.get(Apis.GetGlobalCommunityPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more post');
    }
});

const fetchCommunityCategoryData = createAsyncThunk('posts/fetchCommunityCategory', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityCategoryData(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch community category');
    }
});

const fetchTopCommunitySchoolData = createAsyncThunk('posts/fetchTopCommunitySchool', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetTopCommunitySchool);
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityStatsData = createAsyncThunk('posts/fetchCommunityStatsData', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityStats(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityTopRatedPost = createAsyncThunk('posts/fetchCommunityTopRatedPost', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityTopRatedPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityPostTopUsers = createAsyncThunk('posts/fetchCommunityPostTopUsers', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityPostTopUsers(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchUserCommunityPost = createAsyncThunk('posts/fetchUserCommunityPost', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetUserCommunityPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchUserCollaborationCommunityPost = createAsyncThunk('posts/fetchUserCollaborationCommunityPost', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetUserCollaborationCommunityPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityStudentSpotlight = createAsyncThunk('posts/fetchStudentSpotlight', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityStudentSpotlights(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchMoreCommunityStudentSpotlight = createAsyncThunk('posts/fetchMoreStudentSpotlight', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityStudentSpotlights(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityAssociatedEntity = createAsyncThunk('posts/fetchCommunityAssociatedEntity', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityAssociatedEntity(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityPendingPosts = createAsyncThunk('posts/fetchCommunityPendingPosts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityPendingPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchCommunityFeaturePosts = createAsyncThunk('posts/fetchCommunityFeaturePosts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityFearturesPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchMyPendingPosts = createAsyncThunk('posts/fetchMyPendingPosts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetMyPendingPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});

const fetchJuniorReports = createAsyncThunk('getJuniorReports/fetchJuniorReports', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetJuniorReportsData(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more post');
    }
});

const fetchMoreJuniorReports = createAsyncThunk('getJuniorReports/fetchMoreCommunityPendingPosts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetJuniorReportsData(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more post');
    }
});

const fetchCommunityLibraryPost = createAsyncThunk('posts/fetchCommunityLibraryPost', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityLibraryPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchMoreCommunityLibraryPost = createAsyncThunk('posts/fetchMoreCommunityLibraryPost', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityLibraryPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

export const postsThunks = { 
    fetchGlobalCommunityPost, 
    fetchMoreGlobalCommunityPost, 
    fetchCommunityCategoryData, 
    fetchTopCommunitySchoolData, 
    fetchCommunityStatsData, 
    fetchCommunityTopRatedPost, 
    fetchCommunityPostTopUsers, 
    fetchUserCommunityPost, 
    fetchUserCollaborationCommunityPost,
    fetchCommunityStudentSpotlight,
    fetchCommunityAssociatedEntity,
    fetchCommunityPendingPosts,
    fetchMyPendingPosts,
    fetchJuniorReports,
    fetchMoreJuniorReports, 
    fetchCommunityFeaturePosts,
    fetchMoreCommunityStudentSpotlight,
    fetchCommunityLibraryPost,
    fetchMoreCommunityLibraryPost 
};

