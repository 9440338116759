import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";

const fetchTrendingNews = createAsyncThunk('trending/fetch', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.MLGetFeturedNews(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchMoreTrendingNews = createAsyncThunk('trending/fetchMore', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.MLGetFeturedNews(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more post');
    }
});

export const trendingThunk = { fetchTrendingNews, fetchMoreTrendingNews };

