import { createSelector, createSlice } from "@reduxjs/toolkit";
import { trendingThunk } from "./trendingThunkAction";

const initialState = {
    pending: false,
    loadMorePending: false,
    trending: {},
    error: false,
};
  

/* Product Slice configuration */
const trendingSlice = createSlice({
    name: 'trendingData',
    initialState,
    reducers: {
        saveTrendingArticle: (state, action) => {
            const { articleId } = action.payload;
            const trendingData = state.trending.data;
            let index = trendingData && trendingData?.findIndex(article => article?._id === articleId);
            if (index > -1) {
                state.trending.data[index].isSaved = !state.trending.data[index].isSaved;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(trendingThunk.fetchTrendingNews.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(trendingThunk.fetchTrendingNews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const trendingData = action.payload || {};
                state.trending = trendingData;

            })
            .addCase(trendingThunk.fetchTrendingNews.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(trendingThunk.fetchMoreTrendingNews.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(trendingThunk.fetchMoreTrendingNews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newtrendingData = action.payload?.data || [];
                state.trending.data = [...state.trending.data, ...newtrendingData];
            })
            .addCase(trendingThunk.fetchMoreTrendingNews.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
})

export const getTrendingNewsSelector = createSelector((state) => state?.trending, (trending) => trending);
export const { saveTrendingArticle } = trendingSlice.actions;
export default trendingSlice.reducer;