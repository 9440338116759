import { createSelector, createSlice } from "@reduxjs/toolkit";
import { userThunks } from "./userThunkAction";

const initialState = {
    userDetails: {},
    status: 'idle',
    error: null
}

/* Product Slice configuration */
const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        resetUserDataByCustomUrl: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(userThunks.fetchUserDataByCustomUrl.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(userThunks.fetchUserDataByCustomUrl.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedUserData = action.payload || {};
                state.userDetails = updatedUserData;
            })
            .addCase(userThunks.fetchUserDataByCustomUrl.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getUserDetailsSelector = createSelector((state) => state?.userDatailsData, (userDetails) => userDetails);
export const { resetUserDataByCustomUrl } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;