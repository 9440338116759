import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";

const fetchUserDataByCustomUrl = createAsyncThunk('users/fetchUserDataByCustomUrl', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetUserDataByCustomUrl(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

export const userThunks = { fetchUserDataByCustomUrl }