import { createSelector, createSlice } from "@reduxjs/toolkit";
import { postsThunks } from "./postsThunkAction";


const initialState = {
    postsData: {},
    communityCategoryData: {},
    topCommunitySchool: {},
    communityStatsData: {},
    communityTopRatedPostData: {},
    communityPostTopUsersData: {},
    communityCollaborationUserPostData: {},
    studentSpotlightsData: {},
    userPostsData: {},
    associatedEntityData: {},
    pendingPostData: {},
    myPendingPostData: {},
    juniorReportsData: {},
    featuredPostData: {},
    communityLibraryPostData: {},
    status: 'idle',
    error: null
}

/* Product Slice configuration */
const postsSlice = createSlice({
    name: 'postsData',
    initialState,
    reducers: {
        resetPostsAuthData: () => initialState,
        resetCommunityLibraryData: (state) => {
            state.communityLibraryPostData = {}
        },
        updateCommunityPostData: (state, action) => {
            const { postId, description, attachmentTitle } = action.payload;
            const postListData = state.postsData?.body?.content;
            let index = postListData?.findIndex(data => data?.id === postId);
            if (index > -1) {
                state.postsData.body.content[index].description = description;
                if(state.postsData.body.content[index].attachmentIds?.length > 0) {
                    state.postsData.body.content[index].attachmentIds[0].title =  attachmentTitle;  
                }
            }
        },
        deleteCommunityPostData: (state, action) => {
            const { postId } = action.payload;
            const postListData = state.postsData?.body?.content;
            let index = postListData?.findIndex(data => data?.id === postId);
            if (index > -1) {
                state.postsData.body.content?.splice(index, 1);
            }
        },
        removePendingPostData: (state, action) => {
            const { postId, dataType } = action.payload;
            const pendingPostData = state[dataType]?.body?.content;
            let index = pendingPostData?.findIndex(data => data?.id === postId);
            if (index > -1) {
                state[dataType].body.content?.splice(index, 1);
            }
        },
        updateCommunityFeaturePostData: (state, action) => {
            const { postId, featuredPost } = action.payload;
            const postListData = state.postsData?.body?.content;
            let index = postListData?.findIndex(data => data?.id === postId);
            if (index > -1) {
                state.postsData.body.content[index].featuredPost = featuredPost;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postsThunks.fetchGlobalCommunityPost.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchGlobalCommunityPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPostsData = action.payload || {};
                state.postsData = newPostsData;
            })
            .addCase(postsThunks.fetchGlobalCommunityPost.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchMoreGlobalCommunityPost.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchMoreGlobalCommunityPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPosts = action.payload?.body;
                const newPostsData = action.payload?.body?.content || [];
                state.postsData.body = { ...newPosts, content: [...state.postsData?.body?.content, ...newPostsData] };
            })
            .addCase(postsThunks.fetchMoreGlobalCommunityPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityCategoryData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityCategoryData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityCategory = action.payload || {};
                state.communityCategoryData = newCommunityCategory;
            })
            .addCase(postsThunks.fetchCommunityCategoryData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchTopCommunitySchoolData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchTopCommunitySchoolData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newTopCommunitySchool = action.payload || {};
                state.topCommunitySchool = newTopCommunitySchool;
            })
            .addCase(postsThunks.fetchTopCommunitySchoolData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityStatsData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityStatsData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityStats = action.payload || {};
                state.communityStatsData = newCommunityStats;
            })
            .addCase(postsThunks.fetchCommunityStatsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityTopRatedPost.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityTopRatedPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityTopRatedPostData = action.payload || {};
                state.communityTopRatedPostData = newCommunityTopRatedPostData;
            })
            .addCase(postsThunks.fetchCommunityTopRatedPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityPostTopUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityPostTopUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityPostTopUsersData = action.payload || {};
                state.communityPostTopUsersData = newCommunityPostTopUsersData;
            })
            .addCase(postsThunks.fetchCommunityPostTopUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchUserCommunityPost.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchUserCommunityPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newUserCommunityPostData = action.payload || {};
                state.userPostsData = newUserCommunityPostData;
            })
            .addCase(postsThunks.fetchUserCommunityPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchUserCollaborationCommunityPost.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchUserCollaborationCommunityPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityCollaborationUserPostData = action.payload || {};
                state.communityCollaborationUserPostData = newCommunityCollaborationUserPostData;
            })
            .addCase(postsThunks.fetchUserCollaborationCommunityPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityStudentSpotlight.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityStudentSpotlight.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityStudentSpotlightData = action.payload || {};
                state.studentSpotlightsData = newCommunityStudentSpotlightData;
            })
            .addCase(postsThunks.fetchCommunityStudentSpotlight.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchMoreCommunityStudentSpotlight.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchMoreCommunityStudentSpotlight.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPosts = action.payload?.body;
                const newPostsData = action.payload?.body?.content || [];
                state.studentSpotlightsData.body = { ...newPosts, content: [...state?.studentSpotlightsData?.body?.content, ...newPostsData] };
            })
            .addCase(postsThunks.fetchMoreCommunityStudentSpotlight.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityAssociatedEntity.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityAssociatedEntity.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newAssociatedEntityData = action.payload || {};
                state.associatedEntityData = newAssociatedEntityData;
            })
            .addCase(postsThunks.fetchCommunityAssociatedEntity.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityPendingPosts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityPendingPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPendingPostData = action.payload || {};
                state.pendingPostData = newPendingPostData;
            })
            .addCase(postsThunks.fetchCommunityPendingPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchJuniorReports.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchJuniorReports.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const juniorReportsData = action.payload || {};
                state.juniorReportsData = juniorReportsData;
            })
            .addCase(postsThunks.fetchJuniorReports.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchMoreJuniorReports.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchMoreJuniorReports.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPosts = action.payload?.body;
                const newPostsData = action.payload?.body?.content || [];
                state.juniorReportsData.body = { ...newPosts, content: [...state.juniorReportsData?.body?.content, ...newPostsData] };
            })
            .addCase(postsThunks.fetchMoreJuniorReports.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            
            .addCase(postsThunks.fetchCommunityFeaturePosts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityFeaturePosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newFeaturedPostData = action.payload || {};
                state.featuredPostData = newFeaturedPostData;
            })
            .addCase(postsThunks.fetchCommunityFeaturePosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchMyPendingPosts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchMyPendingPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newMyPendingPostData = action.payload || {};
                state.myPendingPostData = newMyPendingPostData;
            })
            .addCase(postsThunks.fetchMyPendingPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchCommunityLibraryPost.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchCommunityLibraryPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityLibraryPostData = action.payload || {};
                state.communityLibraryPostData = newCommunityLibraryPostData;
            })
            .addCase(postsThunks.fetchCommunityLibraryPost.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(postsThunks.fetchMoreCommunityLibraryPost.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(postsThunks.fetchMoreCommunityLibraryPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newCommunityLibraryPost = action.payload?.body;
                const newCommunityLibraryPostData = action.payload?.body?.content || [];
                state.communityLibraryPostData.body = { ...newCommunityLibraryPost, content: [...state.communityLibraryPostData?.body?.content, ...newCommunityLibraryPostData] };
            })
            .addCase(postsThunks.fetchMoreCommunityLibraryPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
})

export const getGlobalCommunityPostSelector = createSelector((state) => state?.postsData, (postsData) => postsData);
export const getCommunityCategorySelector = createSelector((state) => state?.postsData, (communityCategoryData) => communityCategoryData);
export const getTopCommunitySchoolSelector = createSelector((state) => state?.postsData, (topCommunitySchool) => topCommunitySchool);
export const getCommunityStatsSelector = createSelector((state) => state?.postsData, (communityStatsData) => communityStatsData);
export const getCommunityTopRatedPostSelector = createSelector((state) => state?.postsData, (communityTopRatedPostData) => communityTopRatedPostData);
export const getCommunityPostTopUsersSelector = createSelector((state) => state?.postsData, (communityPostTopUsersData) => communityPostTopUsersData);
export const getUserCommunityPostSelector = createSelector((state) => state?.postsData, (userPostsData) => userPostsData);
export const getUserCollaborationCommunityPostSelector = createSelector((state) => state?.postsData, (communityCollaborationUserPostData) => communityCollaborationUserPostData);
export const getCommunityStudentSpotlightSelector = createSelector((state) => state?.postsData, (studentSpotlightsData) => studentSpotlightsData);
export const getCommunityAssociatedEntitySelector = createSelector((state) => state?.postsData, (associatedEntityData) => associatedEntityData);
export const getCommunityPendingPostsSelector = createSelector((state) => state?.postsData, (pendingPostData) => pendingPostData);
export const getMyPendingPostsSelector = createSelector((state) => state?.postsData, (myPendingPostData) => myPendingPostData);
export const getCommunityFeaturedPostsSelector = createSelector((state) => state?.postsData, (featuredPostData) => featuredPostData);
export const getJuniorReportSelector = createSelector((state) => state?.postsData, (juniorReportsData) => juniorReportsData);
export const getCommunityLibraryPostSelector = createSelector((state) => state?.postsData, (communityLibraryPostData) => communityLibraryPostData);
export const { resetPostsAuthData, updateCommunityPostData, deleteCommunityPostData, removePendingPostData, updateCommunityFeaturePostData, resetCommunityLibraryData } = postsSlice.actions;
export default postsSlice.reducer;